export default {
    name: "warrantoftheday",
    created () { },
    mounted () {
        this.dayWarrantsLoad();
    },
    data () {
        return {
            dsplay_nmll: [],
        }
    },
    methods: {
        dayWarrantsLoad () {
            //let this  = this
            $.ajax({
                type: "POST",
                dataType: 'json',
                url: this.$js.webservice + "GetDeployedWarrantOfTheDay",
                data: { token: "webkey" },
                success: (response) => {
                    console.log(response);
                    if (response.status == 'SUCCESS') {
                        var ricList = [];
                        var src = "image/day_pic1.png";
                        for (var i = 1; i < 6; i++) {
                            for (var key in response.text[0]) {
                                if (("ric" + i) == key) {
                                    if (response.text[0][key] !== "") {
                                        ricList.push(response.text[0][key]);
                                    }
                                }
                                if (("image" + i) == key) {
                                    if (response.text[0][key] !== "") {
                                        $("#dayimage" + i).attr("src", response.text[0][key]);
                                    } else {
                                        $("#dayimage" + i).attr("src", src);
                                    }
                                }
                            }
                        }
                        //先隐藏
                        for (var i = 0; i < 5; i++) {
                            $("#daywarrants-tab" + i).css("display", "none");
                        }
                        //再展示
                        var len = ricList.length;
                        for (var i = 0; i < len; i++) {
                            $("#daywarrants-tab" + i).css("display", "block");
                        }
                        var ric = ricList.join(',');
                        this.getRicData(ric, ricList);
                    }
                },
                error: function (XMLHttpRequest) {
                }
            });
        },
        getRicData (ric, list) {
            $.ajax({
                type: "POST",
                dataType: 'json',
                url: this.$js.webservice + "GetMarketData",
                data: { token: "webkey", ric: ric, type: 'warrantdata' },
                success: (response) => {
                    console.log(response);
                    if (response.status == 'SUCCESS') {
                        var dataList = [];
                        $.each(list, (index, data) => {
                            for (var key in response) {
                                if (data == key) {
                                    dataList.push(response[data]);
                                    this.getRicToDetails(index, data);/*便于传值跳转至details*/
                                }
                            }
                        });

                        if (dataList.length > 0) {
                            $.each(dataList, (index, data) => {
                                this.dsplay_nmll.push(data.dsply_nmll);
                                var i = index + 1;
                                var PCTCHNG = data.ACVOL_1 == 1 ? data.BID_PCTCHNG : data.PCTCHNG
                                //BID_PCTCHNG
                                if (PCTCHNG !== "-" && this.$js.formatNumber(PCTCHNG, 1, 0) >= 0) {//判断红绿箭头
                                    $("#pc" + i).parent("div").prepend('<span class="bi bi-caret-up-fill index-content-centre-data-icon-rise col-1" aria-hidden="true"></span>');
                                    $("#pc" + i).html(PCTCHNG !== "-" ? this.$js.formatNumber(PCTCHNG, 1, 1) + " %" : "-");
                                } else if (PCTCHNG !== "-" && this.$js.formatNumber(PCTCHNG, 1, 0) < 0) {
                                    $("#pc" + i).parent("div").prepend('<span  class="bi bi-caret-down-fill index-content-centre-data-icon-decline col-1" aria-hidden="true"></span>');
                                    $("#pc" + i).html(PCTCHNG !== "-" ? Math.abs(this.$js.formatNumber(PCTCHNG, 1, 1)) + " %" : "-");
                                }

                                //underlying_pctchng
                                if (data.underlying_pctchng && this.$js.formatNumber(data.underlying_pctchng, 1, 0) >= 0) {
                                    $("#nc" + i).parent("div").prepend('<span class="bi bi-caret-up-fill index-content-centre-data-icon-rise col-1" aria-hidden="true"></span>');
                                    $("#nc" + i).html(data.underlying_pctchng ? this.$js.formatNumber(data.underlying_pctchng, 1, 1) + " %" : "-");
                                }
                                else if (data.underlying_pctchng && this.$js.formatNumber(data.underlying_pctchng, 1, 0) < 0) {
                                    $("#nc" + i).parent("div").prepend('<span  class="bi bi-caret-down-fill index-content-centre-data-icon-decline col-1" aria-hidden="true"></span>');
                                    $("#nc" + i).html(data.underlying_pctchng ? Math.abs(this.$js.formatNumber(data.underlying_pctchng, 1, 1)) + " %" : "-");
                                }

                                $("#dsply_nmll" + i).html(data.dsply_nmll);
                                $("#currency" + i).html(data.underlying_ticker);
                                $("#exepriceVal" + i).html(data.exercise_price !== "N/A" ? this.$js.curreryRetrun(data.underlying_curr) + " " + this.$js.formatNumber(data.exercise_price, 0, 1) : "N/A");
                                $("#exerciseRatio" + i).html(data.conv_ratio !== "N/A" ? this.$js.formatNumber(data.conv_ratio, 4, 0) : "N/A");
                                $("#expriyDate" + i).html(data.maturity);
                                $("#sensitivity" + i).html(data.sensitivity !== "N/A" ? this.$js.formatNumber(data.sensitivity, 1, 0) : "N/A");
                                $("#effectiveGearing" + i).html(data.effective_gearing !== "N/A" ? this.$js.formatNumber(data.effective_gearing, 1, 0) : "N/A");
                                $("#impliedVolatility" + i).html(data.implied_volatility !== "N/A" ? this.$js.formatNumber(data.implied_volatility, 1, 0) : "N/A");
                            });
                        }
                    }
                },
                error: function (XMLHttpRequest) {
                }
            });
        },
        getRicToDetails (index, ric) {
            $("#daywarrants-tab" + index).click(() => {
                localStorage.setItem('detailsRic', ric);
                let routes = this.$router.resolve({
                    path: "/id/WarrantDetails",
                    query: { dsplay_nmll: this.dsplay_nmll[index] }
                });
                location.href = routes.href;
                //location.href = "warrantdetails?dsplay_nmll=" + this.dsplay_nmll[index]});
            });
        }
    }
};